import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout/index"
import Container from "../components/Layout/Container"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    {
      spilledMeds: file(relativePath: { eq: "pages/404__spilledMeds.png" }) {
        childImageSharp {
          fixed(width: 400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      spilledMedsMobile: file(
        relativePath: { eq: "pages/404__spilledMeds.png" }
      ) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const spilledMeds = data.spilledMeds.childImageSharp.fixed
  const spilledMedsMobile = data.spilledMedsMobile.childImageSharp.fixed

  return (
    <Layout seoTitle="404: Not found">
      <Container customClassName="my-3" isCentered>
        <center className="my-5">
          <Img fixed={spilledMeds} className="is-hidden-mobile" />
          <Img
            fixed={spilledMedsMobile}
            className="is-hidden-desktop is-hidden-tablet"
          />
          <h2>Something’s wrong here...</h2>
          <p>
            We can’t find the page you entered. You may visit our{" "}
            <Link to="/help-center" className="has-text-weight-bold">
              Help Center
            </Link>
            , <br className="is-hidden-mobile" /> or{" "}
            <Link to="/help-center#contact-us" className="has-text-weight-bold">
              contact us
            </Link>{" "}
            for further assistance.
          </p>
        </center>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
